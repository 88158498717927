import React from 'react';
import styled from 'styled-components';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import { FaShopify } from 'react-icons/fa';
import { FaLinkedinIn } from 'react-icons/fa';
import { GoMail } from 'react-icons/go';

const IconWrapper = styled.div`
  text-align: center;
  margin-top: 2em;

  @media (max-width: 425px) {
    margin-top: 1em;
  }

  & a {
    color: white;
    text-decoration: none;
    margin-left: 1.75%;
    margin-right: 1.75%;
  }
`;

const Icon = styled(OutboundLink)`
  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 425px) {
    font-size: 10px;
  }

  @media (max-width: 375px) {
    font-size: 8px;
  }

  &:hover {
    color: ${props => props.iconColor};
  }
`;

const ContactIcons = () => (
  <IconWrapper>
    <Icon
      iconColor="#0077b5"
      title="LinkedIn"
      href=""
      target="_blank"
      rel="noopener"
    >
      <FaLinkedinIn size={'3em'} />
    </Icon>
    <Icon
      iconColor="#333"
      title="Shopify (Brilega)"
      href="brilega.com"
      target="_blank"
      rel="noopener"
    >
      <FaShopify size={'3em'} style={{}} />
    </Icon>
    <Icon
      iconColor="#0072c6"
      title="team@velitum.com"
      href="mailto:team@velitum.com"
    >
      <GoMail size={'3em'} />
    </Icon>
  </IconWrapper>
);

export default ContactIcons;
